export enum competitionCategoryFriendlyType {
  Kategori1 = <any>'kategori1',
  Kategori2 = <any>'kategori2',
  Kategori3 = <any>'kategori3',
  Kategori4 = <any>'kategori4'
}

export enum oldCompetitionClassFriendlyType {
  her = <any>'her',
  dam = <any>'dam',
  par = <any>'par',
  pig = <any>'pig',
  dre = <any>'dre'
}

export enum SysCompetitionClubRoleType {
  Medlem = <any>'medlem',
  Aktiv_medlem = <any>'aktiv_medlem',
  Passiv_medlem = <any>'passiv_medlem',
  Aeresmedlem = <any>'aeresmedlem',
  Formand = <any>'formand',
  Kasserer = <any>'kasserer',
  Holdkaptajn = <any>'holdkaptajn',
  Bestyrelsesmedlem = <any>'bestyrelsesmedlem'
}

export enum competitionCategory2FriendlyType {
  alle = <any>'alle',
  jfm = <any>'jfm',
  sm = <any>'sm'
}

export enum SysCompetitionClubWheelchairFriendlyType {
  Ja = <any>'Ja',
  Nej = <any>'Nej'
}

export interface SysCompetitionProductTypeId {
  id: null
  produkt_type_status: boolean
  produkt_type_navn: string
  produkt_type_kontofra1: string // To be deleted
  produkt_type_kontofra2: string // To be deleted
  produkt_type_kontofra3: string // To be deleted
  produkt_type_kontotil: string // To be deleted
  produkt_type_beskrivelse: string
  produkt_type_unikt: boolean
  created_at: string
  updated_at: string
  konti_fra1: number | null
  konti_fra2: number | null
  konti_fra3: number | null
  konti_til: number
}

export interface SysCompetitionProductIds {
  id: null
  produkt_status: boolean
  produkt_navn: string
  produkt_pris: number
  produkt_datofra: string
  produkt_datotil: string
  produkt_beskrivelse: string
  // bruger_id: number
  user_id: number
  produkt_type_id: number
}

export interface SysCompetitionRegistrationId {
  id: null
  staevne_tilmelding_status: boolean
  staevner_id: number[]
  staevne_raekker_id: number
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysCompetitionMembers {
  id: null
  medlem_status: boolean
  medlem_vejnummer: string
  medlem_foedselsdag: string
  medlem_begyndelse: string
  /* medlem_rolle: number */
  medlem_licens: number
  medlem_licens_slut: string
  medlem_sidste_kamp: string
  medlem_paradart: boolean
  medlem_karantaene: boolean
  medlem_karantaene_dato: string
  medlem_karantaene_periode: string
  published_at: string
  created_at: string
  updated_at: string
  bypost_id: number
  // bruger_id: number
  user_id: number
  region_id: number
  koen_id: number
  medlem_klubrolle: SysCompetitionClubRoleType
  klubber_id: number
  staevne_tilmelding_id: SysCompetitionRegistrationId[]
}

export interface SysCompetitionForeignPlayer {
  id: null
  ud_spiller_status: boolean
  staevne_tilmelding_id: number[]
  koen_id: number
  ud_foedselsdag: string
  ud_spiller_navn: string
  ud_spiller_land: string
  ud_spiller_mail: string
  ud_spiller_fornavn: string
  ud_spiller_efternavn: string
  ud_spiller_paradart: boolean
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysCompetitionClubId {
  id: null
  klubber_status: boolean
  klubber_klubnavn: string
  klubber_logo: string
  klubber_vejnavn: string
  bypost_id: number
  region_id: number
  klubber_telefonnummer: string
  klubber_email: string
  klubber_saldo: number
  klubber_klubbeskrivelse: string
  klubber_aaben: string
  klubber_baner: number
  klubber_hjemmekampe: number
  klubber_etableringsaar: string
  klubber_koerestolsvenlig: SysCompetitionClubWheelchairFriendlyType
  klubber_ansvarlig_id: number
  events_id: number
  published_at: string
  created_at: string
  updated_at: string
  klubber_slug: string
}

export interface SysCompetitionRowsId {
  id: null
  staevne_raekker_status: boolean
  staevne_raekker_navn: string
  staevne_raekker_tilmeldingsgebyr: number
  staevner_id: number[]
  staevne_raekker_type: number
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysCompetitionRankingsPointId {
  id: null
  staevne_ranglistepoint_status: boolean
  staevne_ranglistepoint_placering: string
  staevne_ranglistepoint_point: number
  staevne_ranglistepoint_kategori: competitionCategoryFriendlyType
  staevne_ranglistepoint_raekke: boolean
  staevners_id: number[]
  published_at: string
  created_at: string
  updated_at: string
}

export default interface SysCompetition {
  id: null
  staevne_status: boolean
  staevne_navn: string
  staevne_kategori: competitionCategoryFriendlyType
  staevne_kat2: competitionCategory2FriendlyType
  staevne_sidstetilmelding: string
  staevne_start: string
  staevne_slut: string
  staevne_spillested: string
  staevne_invitation: any[]
  klubber_id: SysCompetitionClubId
  staevne_raekker_id: SysCompetitionRowsId[]
  staevne_ranglistepoint_id: SysCompetitionRankingsPointId
  staevne_staevneleder: string
  staevne_overdommer: string
  staevne_link: string
  produkts_id: SysCompetitionProductIds[]
  produkt_type_id: SysCompetitionProductTypeId
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysCompetitionRows {
  id: null
  staevne_raekker_status: boolean
  staevne_raekker_navn: string
  staevne_raekker_tilmeldingsgebyr: number
  staevner_id: SysCompetition[]
  staevne_raekker_type: number
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysCompetitionRegistration {
  id: null
  staevne_tilmelding_status: boolean
  medlem_id: SysCompetitionMembers[]
  usp_id: SysCompetitionForeignPlayer[]
  staevner_id: SysCompetition[]
  staevne_raekker_id: SysCompetitionRowsId
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysCompetitionRankingsPoint {
  id: null
  staevne_ranglistepoint_status: boolean
  staevne_ranglistepoint_placering: string
  staevne_ranglistepoint_point: number
  staevne_ranglistepoint_kategori: competitionCategoryFriendlyType
  staevne_ranglistepoint_raekke: boolean
  staevners_id: SysCompetition[]
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysCompetitionResults {
  id: null
  staevne_resultater_status: boolean
  medlem_id: SysCompetitionMembers
  staevne_raekker_id: SysCompetitionRowsId
  staevner_id: SysCompetition
  staevne_resultater_dato: string
  staevne_ranglistepoint_id: SysCompetitionRankingsPoint
  staevne_ranglistepoint: number
  staevne_ranglistepoint_oprindelig: number
  staevne_resultater_navn: string
  staevne_resultater_placering: string
  published_at: string
  created_at: string
  updated_at: string
}

export interface SysForeignCompetition {
  id: null
  ud_staevne_navn: string
  ud_staevne_dato: string
  created_at: string
  updated_at: string
}

export interface SysForeignCompetitionResults {
  id: null
  medlem_id: SysCompetitionMembers
  ud_staevner_id: SysForeignCompetition
  ud_resultat_placering: string
  ud_resultat_dato: string
  ud_resultat_ranglistepoint: number
  staevne_raekker_id: SysCompetitionRowsId
  created_at: string
  updated_at: string
}

export interface SysOldCompetitionResults {
  id: null
  Navn: string
  Klub: string
  Licens: number
  Staevne: string
  Dato: string
  Klasse: oldCompetitionClassFriendlyType
  Point: number
  Placering: number
  created_at: string
  updated_at: string
}
